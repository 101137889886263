<template>
    <div class="module">
      <div class="mod">
        <div class="hd"><h3 class="tit">交易额总计</h3></div>
        <div class="bd">
          <div class="digits" v-html="gmvData">
            <div class="digit">0</div>
            <div class="digit">0</div>
            <div class="digit">0</div>
            <div class="digit">0</div>
            <div class="digit">0</div>
            <div class="digit">0</div>
            <div class="digit">0</div>
            <div class="digit">0</div>
            <div class="digit">0</div>
            <div class="digit">0</div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import {getAction} from '@/utils/http';
export default {
  name:'gmv',
  components: {

  },
  data () {
    return {
      url:'/trans/total',
      gmvData:''
    };
  },
  mounted () {
    this.fetchData();
  },
  methods: {
    fetchData(){
      let maxbit = this.GLOBAL.maxBit;
      getAction(this.url).then(res=>{
        let data = res.data, tmpArray = [], htmlArray = [];
        //console.log("resssss:",res);
        if(data.success){
          let total = data.result;
          // const splitToDigit = n => (n + '').split('').map(Number);

          // tmpArray = splitToDigit(total);
          tmpArray =(Number(total)+ '').split('')
          if(tmpArray.length<=maxbit){
            let k = maxbit - tmpArray.length;
            for(let i=0; i<k; i++){
              tmpArray.unshift(0)
            }
          }
          tmpArray.forEach(item=>{
            htmlArray.push('<div class="digit">'+item+'</div>')
          })
          this.gmvData = htmlArray.join("");
        }
        
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.module {
    min-width:200px;
    .mod {
      .hd {
        .tit {
          font-size:16px; font-weight:bold; color:#06e6e8; line-height:1; text-align:center;
        }
      }
      .bd {
        ::v-deep .digits {
          display:flex; justify-content:center; align-items:center;
          .digit {
            background:url(~@/assets/digital_box.png) no-repeat center center/contain; width:58px; height:79px; box-sizing:border-box; display:flex; justify-content:center; align-items:center; font-size:32px; font-weight:bold; color:#06e6e8;
          }
        }
      }
    }
}
</style>
